import React from "react";
import emergency4 from "../../../static/emergency/emergency4.png";
import ambulance from "../../../static/solutions/ambulance.jpg";
import firefighters2 from "../../../static/solutions/firefighters2.jpg";
import motorola from "../../../static/partners/motorola.png";
import hp from "../../../static/partners/hp.png";
import oracle from "../../../static/partners/oracle.png";

import Layout from "../../components/Layout/Layout";

const EmergencyServices = () => {
  return (
    <Layout>
      <div className="page ">
        <div className="row  page__section --grid-1-1 --align-left">
          <div>
            <h1 className="section-paragraph">INDUSTRY</h1>
            <h1 className="section-title">Emergency Services</h1>
            <p className="section-description">
              We implemented the 112 service in Moldova and continue to maintain
              the hardware and software, and work with our clients and partners
              on extending and improving these services.
            </p>
          </div>
          <div style={{ textAlign: "center" }}>
            <img className="section-image" src={emergency4} alt="" />
          </div>
        </div>

        <div className="block--gray">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">
                Technology supplier for the 112 Service
              </h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1 --align-left">
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-45"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={ambulance} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Emergency Services</span>
                  <p className="section-title-small --align-left ">
                    Ambulance Services
                  </p>
                  <p className="section-description --align-left ">
                    The ambulance service is the prime user of the 112 service
                    and we work closely with the client to ensure the service
                    continues to perform at the hightest levels of quality.
                  </p>
                  {/* <span className="learn-more">
                    LEARN MORE
                  </span> */}
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div
                className="studies-tile --align-left height-45"
                style={{ backgroundColor: "#fff" }}
              >
                <img className="section-image" src={firefighters2} alt="" />
                <div className="studies-tile__content">
                  <span className="studies-title">Emergency services</span>
                  <p className="section-title-small --align-left ">
                    Firefighters
                  </p>
                  <p className="section-description --align-left ">
                    We implemented software platforms for firefighters and also
                    supplied communication equipment and other specialized
                    hardware for critical operations.
                  </p>
                  {/* <span className="learn-more">
                      LEARN MORE
                    </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row page__section --grid-1-1 --align-left">
          <div className="tile-container">
            <div className="info-tile  --align-left height-25">
              <div className="icon-container">
                <i className="tile-icon icon icon-signal " />
              </div>
              <p className="section-title-small --align-left ">
                High availability
              </p>
              <p className="section-description --align-left ">
                We operate critical services that demand tough SLAs which we
                have been able to maintain constantly. We continue to employ the
                best teams that are there 24/7 and the 112 service have been
                proven a great success.
              </p>
            </div>
          </div>
          <div className="tile-container">
            <div className="info-tile  --align-left height-25">
              <div className="icon-container">
                <i className="tile-icon icon icon-network " />
              </div>
              <p className="section-title-small --align-left ">
                Security at all levels
              </p>
              <p className="section-description --align-left ">
                Security is a paramaount aspect in the daily operations of
                Police, Border Police, Ambulance or Firefighters. Or systems
                meet the high standards required and we continue to improve this
                area.
              </p>
            </div>
          </div>
        </div>
        <div className="block--white">
          <div className="row  page__section --grid-single --align-left">
            <div>
              <h1 className="section-sub-title">Our Partners</h1>
            </div>
          </div>
          <div className="row  page__section --grid-1-1-1 --align-left">
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "4rem" }}
                    className="partners-image"
                    src={motorola}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Motorola is the leading global supplier of TETRA equipment
                    and is our partner in Moldova. We supply Motorola equipment
                    to the Police, Border Police, Customs, 112 service, and
                    others.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "7rem" }}
                    className="partners-image"
                    src={hp}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Through our partner HPE, we supply, configure and maintain
                    top of the line servers, storage and networking equipment
                    for our clients.
                  </p>
                </div>
              </div>
            </div>
            <div className="tile-container">
              <div className="partners-tile --align-left height-35">
                <div className="partners-image-container">
                  <img
                    style={{ height: "2rem" }}
                    className="partners-image"
                    src={oracle}
                    alt=""
                  />
                </div>
                <div className="partners-tile__content">
                  <p className="section-description --align-left ">
                    Oracle databases are present in the wide majority of
                    government organizations and our specialists are always
                    ready to offer professional assistance in operating the
                    critical stores of data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EmergencyServices;
